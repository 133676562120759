<template>
    <div class="type-list">
        <ul>
            <li
                :class="{'filled':item.state === 1,'notfilled':item.state === 0, 'active': currentIndex === index,}"
                v-for="(item, index) in typeList"
                :key="index"
                @click="changeType(index, item)"
            >
                <div class="con">
                    <span class="status" v-if="item.state === 1">已填</span>
                    <span class="status" v-else>未填</span>
                    <span class="tit">{{ item.name }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        typeList: {
            type: Array,
        },
        resetIndex: {
            type: [String],
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    watch: {
        resetIndex() {
            this.currentIndex = 0;
        },
    },
    computed: {},
    methods: {
        changeType(index, item) {
            const reportTip = util.getCookie('reportTip');
            if (reportTip !== '1') {
                this.$confirm('是否在离开页面前保存修改？', '确认信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '不再提示',
                }).then(() => {
                    this.currentIndex = index;
                    this.$emit('changeTable', index, item);
                }).catch(() => {
                    this.currentIndex = index;
                    this.$emit('changeTable', index, item);
                    util.setCookie('reportTip', '1', 90);
                });
            } else {
                this.currentIndex = index;
                this.$emit('changeTable', index, item);
            }
        },
    },
    created() {
        const _this = this;
        setTimeout(function () {
            _this.$emit('changeTable', 0, _this.typeList[0]);
        }, 500);

    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.type-list
    li
        position relative
        .con
            width 1.64rem
            height .56rem
            border 1px solid #D7D7D7
            text-align center
            position relative
            margin-bottom .2rem
            overflow hidden
            cursor pointer
            .tit
                line-height .56rem
                font-size .17rem
            .status
                width .56rem
                height .56rem
                position absolute
                left -.28rem
                top -.28rem
                transform rotate(-45deg)
                -webkit-transform rotate(-45deg)
                -moz-transform rotate(-45deg)
                line-height .92rem
                font-size .12rem
                color #fff
            .filled
                background #5588F1
    li.filled.active
        background url('./../../../../assets/images/bbgl/frame_blue.png') no-repeat
        background-size 100% 100%
        .con
            border none
            color #5588f1
        // &:after
        //         content " "
        //         width .14rem
        //         height .2rem
        //         position absolute
        //         right: .02rem;
        //         top .18rem
        //         background url('./../../../../assets/images/bbgl/arrow_blue.png')
        //         background-size: 100% 100%;
    li.notfilled.active
        background url('./../../../../assets/images/bbgl/frame_red.png') no-repeat
        background-size 100% 100%
        .con
            border none
            color #EB656F
        // &:after
        //         content " "
        //         width .14rem
        //         height .2rem
        //         position absolute
        //         right: .02rem;
        //         top .18rem
        //         background url('./../../../../assets/images/bbgl/arrow_red.png')
        //         background-size: 100% 100%;
    li.filled
        .status
            background #5588F1
    li.notfilled
        .status
            background #EB656F

</style>