<template>
    <div class="slider-con report-slider-con">
        <div class="search-con">
            <el-input
                size="small"
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
            >
            </el-input>
        </div>
        <div class="tree-con">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                ref="tree"
                @node-click="handleNodeClick"
                :highlight-current="true"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div v-if="data.children" class="parent-node">
                        <i v-if="node.expanded" class="iconfont iconshouqi"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai"></i>
                        <span>{{ data.label }}</span>
                    </div>
                    <div v-if="!data.children" class="leaf-node">
                        <template v-if="data.report_state===-1">
                            <i class="iconfont iconlingxing blue"></i>
                            <span>{{ data.label }}</span>
                            <span class="blue status">当前月</span>
                        </template>
                        <template v-if="data.report_state===0">
                            <i class="iconfont iconlingxing red"></i>
                            <span>{{ data.label }}</span>
                            <span class="red status">未报</span>
                        </template>
                        <template v-if="data.report_state===1">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.label }}</span>
                            <span class="green status">已报未审</span>
                        </template>
                        <template v-if="data.report_state===2">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.label }}</span>
                            <span class="green status">退回</span>
                        </template>
                        <template v-if="data.report_state===3">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.label }}</span>
                            <span class="green status">已审</span>
                        </template>
                    </div>
                </span>
            </el-tree>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        refresh: {
            type: [String],
        },
    },
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            currentNode: {},
            defaultKey: [],
            currentNodeKey: '',
            updateNode: {},
        };
    },
    watch: {
        refresh: function () {
            this.updateTree(this.refresh);
        },
    },
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance(report_year, report_month) {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + report_year + '/' + (report_month - 1));
        },
        handleNodeClick(node) {
            this.currentNode = node;
            if (node.report_month) {
                if (node.report_state === -1) {
                    this.addReport();
                } else if (node.report_id === null) {
                    this.addReport();
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (node.report_month > 1) {
                        this.$axios.get('/interfaceApi/report/instance/get/yyqk/' + node.report_year + '/' + (node.report_month - 1))
                            .then(res => {
                                if (res) {
                                    if (res.report_state !== 3) {
                                        this.$message.error('上个月未填报审核通过，请先填申报上个月数据！');
                                    } else {
                                        const reportTip = util.getCookie('reportTip');
                                        // eslint-disable-next-line max-depth
                                        if (reportTip !== '1') {
                                            this.$confirm('是否在离开页面前保存修改？', '确认信息', {
                                                confirmButtonText: '确定',
                                                cancelButtonText: '不再提示',
                                            }).then(() => {
                                                this.$emit('update-month', this.currentNode);
                                            }).catch(() => {
                                                this.$emit('update-month', this.currentNode);
                                                util.setCookie('reportTip', '1', 90);
                                            });
                                        } else {
                                            this.$emit('update-month', this.currentNode);
                                        }
                                    }
                                }
                            }).catch(error => {
                                this.$message.error(error.ErrorCode.Message);
                            });
                    } else {
                        const reportTip = util.getCookie('reportTip');
                        // eslint-disable-next-line max-depth
                        if (reportTip !== '1') {
                            this.$confirm('是否在离开页面前保存修改？', '确认信息', {
                                confirmButtonText: '确定',
                                cancelButtonText: '不再提示',
                            }).then(() => {
                                this.$emit('update-month', this.currentNode);
                            }).catch(() => {
                                this.$emit('update-month', this.currentNode);
                                util.setCookie('reportTip', '1', 90);
                            });
                        } else {
                            this.$emit('update-month', this.currentNode);
                        }
                    }
                }
            }
        },
        // 获取树结构数据
        getTree() {
            this.$axios
                .get('/interfaceApi/report/instance/tree/yyqk/write')
                .then(res => {
                    if (res) {
                        res.map(item => {
                            item.id = item.year + '';
                            item.children.map(v => {
                                v.id = v.report_year + '' + v.report_month;
                            });
                            return item;
                        });
                        this.treeData = res;
                        this.currentNode = res[0].children[0];
                        this.defaultKey.push(res[0].id);
                        this.currentNodeKey = this.currentNode.id;
                        const nodekey = this.currentNodeKey;
                        this.$nextTick(() => {
                            this.$refs.tree.setCurrentKey(nodekey);
                        });
                        if ( this.currentNode.report_state === -1 || this.currentNode.report_id === null) {
                            this.addReport();
                        } else {
                            this.$emit('update-month', this.currentNode);
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        updateTree(refreshId) {
            this.$axios
                .get('/interfaceApi/report/instance/tree/yyqk/write')
                .then(res => {
                    if (res) {
                        res.map(item => {
                            item.id = item.year + '';
                            if (item.id === refreshId) {
                                this.updateNode = item;
                            }
                            item.children.map(v => {
                                v.id = v.report_year + '' + v.report_month;
                                if (v.id === refreshId) {
                                    this.updateNode = v;
                                }
                            });
                            return item;
                        });
                        this.treeData = res;
                        this.defaultKey.push(this.refresh);
                        this.currentNodeKey = this.refresh;
                        const nodekey = this.currentNodeKey;
                        this.$nextTick(() => {
                            this.$refs.tree.setCurrentKey(nodekey);
                        });
                        this.$emit('update-month', this.updateNode);
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加报表
        addReport() {
            const params = {
                business_code: 'yyqk',
                report_year: this.currentNode.report_year,
                report_month: this.currentNode.report_month,
                report_state: this.currentNode.report_state,
            };
            this.$axios
                .post('/interfaceApi/report/instance/add', params)
                .then(res => {
                    if (res) {
                        res.id = res.report_year + '' + res.report_month;
                        this.currentNode = res;
                        this.$emit('update-month', this.currentNode);
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取租户ID
        getTenantId() {
            const TenantId = util.jwtToken('TenantId');
            return TenantId;
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style>
.report-slider-con{
    height:100%;
}

.report-slider-con .search-con{
    padding:.15rem;
}

.report-slider-con .tree-con{
    height:calc(100% - .62rem);
    overflow-y:auto;
    overflow-x: scroll;
}
.report-slider-con .tree-con .el-icon-caret-right:before{
    display: none!important;
}
.report-slider-con .tree-con .iconfont{
    font-size: .14rem;
    margin-right: .1rem;
}
.report-slider-con .tree-con .iconshouqi,.iconzhankai{
    color: #5588f1;
}
.report-slider-con .tree-con .el-tree-node__content{
    height: .32rem;
    line-height: .32rem;
    font-size: .16rem;
}
.report-slider-con .tree-con .blue{
    color: #5588f1;
}
.report-slider-con .tree-con .red{
    color: #eb656f;
}
.report-slider-con .tree-con .green{
    color: #21bdcc;
}
.report-slider-con .iconlingxing{
    font-size: .1rem;
}
.report-slider-con .slot-t-node{
    width: 100%;
}
.report-slider-con .leaf-node{
    margin-left: .1rem;
    position: relative;
}
.report-slider-con .leaf-node .status{
    /* position: absolute;
    right: .3rem; */
    margin-left: .2rem;
    margin-right: .1rem;
}
.report-slider-con .el-tree-node.is-expanded{
    background: #f6fbfd;
}
.report-slider-con .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background: #ecf1f5;
}
/* .report-slider-con .tree-con::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
.report-slider-con .tree-con::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.1);
    background:#d9d7d7;
}
.report-slider-con .tree-con::-webkit-scrollbar-track{
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.1);
    border-radius:10px;
    background:rgba(237,237,237,  0.2);
} */
</style>