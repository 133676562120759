<template>
    <el-container class="el-height report-con">
        <div class="fl expend" @click="closeSlider=false" v-if="closeSlider"></div>
        <el-aside class="slider" :class="{'closedSlider': closeSlider}">
            <slider @update-month="updateMonth" :refresh="refreshTreeId"></slider>
            <div class="close" @click="closeSlider=true" v-if="!closeSlider"></div>
        </el-aside>
        <el-container class="main">
            <el-header class="header clearfix">
                <span class="el_left title">中国联合水泥商混运营情况分析——产销概况</span>
                <div class="el_right btn-group">
                    <el-button
                        class="btn-item"
                        type="primary"
                        size="mini"
                        @click="handelReport"
                        v-if="fillInfo.report_state==='0'||fillInfo.report_state==='2'"
                    >
                        上报
                    </el-button>
                    <el-button
                        class="btn-item"
                        type="primary"
                        size="mini"
                        @click="handelSave"
                        v-if="fillInfo.report_state==='0'||fillInfo.report_state==='2'"
                    >
                        保存
                    </el-button>
                </div>
            </el-header>
            <el-main class="main-bottom">
                <el-row :gutter="20" class="row">
                    <el-col :span="6">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconjindu progress"></span>
                                <label>填报进度</label>
                            </div>
                            <div class="el_right status progress">
                                {{ fillInfo.fillin_count }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconshijian font18 time"></span>
                                <label>填报时间</label>
                            </div>
                            <el-date-picker
                                v-model="fillInfo.created"
                                type="date"
                                suffix-icon="el-icon-date"
                                class="el_right status time"
                                v-if="fillInfo.report_state==='0'||fillInfo.report_state==='2'"
                                @change="changeCreateTime"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                            <div class="el_right status time" v-else :title="fillInfo.created">
                                {{ fillInfo.created }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconzhuangtai state font18"></span>
                                <label>审批状态</label>
                            </div>
                            <div class="el_right status state" v-if="fillInfo.report_state==='0'">
                                未上报
                            </div>
                            <div class="el_right status state" v-else-if="fillInfo.report_state==='1'">
                                已报未审
                            </div>
                            <div class="el_right status state" v-else-if="fillInfo.report_state==='2'">
                                退回
                            </div>
                            <div class="el_right status state" v-else-if="fillInfo.report_state==='3'">
                                已审
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconqiepian options font18"></span>
                                <label>审批意见</label>
                            </div>
                            <div class="el_right status options" :title="fillInfo.approve_opinion">
                                {{ fillInfo.approve_opinion }}
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div class="report-forms">
                    <el-container class="el_height container">
                        <el-aside class="slider">
                            <typeList :type-list="typeListData" @changeTable="handelChangeType" :reset-index="resetId"></typeList>
                        </el-aside>
                        <el-main class="edit-table" ref="tableWrapper" id="tableWrapper">
                            <component
                                :is="zjName"
                                :report-month="reportMonth"
                                ref="table"
                                :type="type"
                                :load-obj="loading"
                                :type-list="typeListData"
                                @save-end="handelSaveEnd"
                                v-if="showTable"
                            ></component>
                        </el-main>
                    </el-container>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import slider from './slider';
import typeList from './typeList';
export default {
    components: { slider, typeList },
    props: {},
    data() {
        return {
            zjResData: {
                total: {},
                concretes: {},
                mortars: {},
            },
            typeListData: [],
            componentArr: [
                { componentName: 'productSaleTable' },
                { componentName: 'productFlowTable' },
                { componentName: 'moneyBackTable' },
                { componentName: 'assetUseTable' },
                { componentName: 'materialPurchaseTable' },
                { componentName: 'stockTable' },
                { componentName: 'payableTable' },
                { componentName: 'costsTable' },
            ],
            panelObj: { },
            type: 'report',
            reportMonth: {},
            fillInfo: {},
            oldData: {},
            typeListIndex: 0,
            loading: null,
            showTable: false,
            refreshTreeId: '',
            resetId: '',
            saveIng: false,
            closeSlider: false, // 收起树结构
        };
    },
    watch: {},
    computed: {
        // 按类型动态加载表格
        zjName: function () {
            let t_url = '';
            let myComponent = null;
            if (this.panelObj.componentName) {
                t_url = this.panelObj.componentName;
                myComponent = () => import(`./../reportApprovaltable/${t_url}.vue`);
            }
            return myComponent;
        },
    },
    methods: {
        // 保存
        handelSave() {
            if (!this.saveIng) {
                this.saveIng = true;
                this.$refs.table.save();
            } else {
                this.$message.warning('保存方法未执行完，请稍后再次点击');
            }
        },
        // 上报
        handelReport() {
            if (!this.saveIng) {
                this.saveIng = true;
                this.$refs.table.save();
                const _this = this;
                setTimeout(() => {
                    this.$axios
                        .put('/interfaceApi/report/instance/report/' + this.reportMonth.report_id)
                        .then(res => {
                            this.$message.success('上报成功');
                            _this.refreshTreeId = _this.reportMonth.id;
                            _this.resetId = _this.reportMonth.id;
                        }).catch(error => {
                            this.$message({
                                message: error.ErrorCode.Message,
                                type: 'error',
                                duration: 4000,
                            });
                        });
                }, 500);
            } else {
                this.$message.warning('保存方法未执行完，请稍后再次点击');
            }

        },
        // 表格保存完毕 触发事件
        handelSaveEnd(typeName, code) {
            if (code === 'success') {
                let count = 0;
                this.typeListData.map(item => {
                    if (item.code === typeName) {
                        item.state = 1;
                    }
                    if (item.state === 1) {
                        count++;
                    }
                    return item;
                });
                this.fillInfo.fillin_count = count + '/8';
            }
            this.saveIng = false;
        },
        // 切换类型
        handelChangeType(index, item) {
            this.typeListIndex = index;
            this.panelObj = this.typeListData[index];
        },
        // 左侧树切换
        updateMonth(data) {
            this.reportMonth = data;
            this.getFillInfo();
            this.typeListIndex = 0;
            this.resetId = this.reportMonth.report_id;
        },
        // 获取填报详情
        getFillInfo() {
            this.$axios
                .get('/interfaceApi/report/instance/fillinfo/' + this.reportMonth.report_id)
                .then(res => {
                    if (res) {
                        res.created = res.created.substring(0, 10);
                        this.fillInfo = res;
                        this.typeListData = this.fillInfo.infos.map((item, index) => {
                            const json = { ...item, ...this.componentArr[index] };
                            return json;
                        });
                        const _this = this;
                        setTimeout(function () {
                            _this.panelObj = _this.typeListData[_this.typeListIndex];
                            _this.showTable = true;
                        }, 0);
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 显示加载层
        showLoading() {
            this.loading = this.$loading({
                target: '#tableWrapper',
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.8)',
            });
        },
        // 改变上报时间
        changeCreateTime() {
            if (this.fillInfo.created) {
                this.$axios
                    .get('/interfaceApi/report/instance/update/created/' + this.reportMonth.report_id + '?created=' + this.fillInfo.created)
                    .then(res => {
                        this.$message.success('修改上报时间成功');
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.warning('请选择正确的时间');
            }

        },
    },
    created() {
    },
};
</script>
<style lang="stylus">
.report-con
    position relative
    .expend
        position absolute
        top 50%
        left 0
        width 14px
        height 24px
        background url(./../images/icon2_normal.png) no-repeat
        background-size 100% 100%
        cursor pointer
        &:hover
            background url(./../images/icon2_highlight.png) no-repeat
            background-size 100% 100%
    .slider
        width 2.6rem!important
        background #fff
        height  100%
        overflow hidden
        position relative
        transition width 0.8s
        -moz-transition width 0.8s /* Firefox 4 */
        -webkit-transition width 0.8s /* Safari 和 Chrome */
        -o-transition width 0.8s /* Opera */
        .close
            position absolute
            top 50%
            right 0
            width 14px
            height 24px
            background url(./../images/icon1_normal.png) no-repeat
            background-size 100% 100%
            cursor pointer
            &:hover
                background url(./../images/icon1_highlight.png) no-repeat
                background-size 100% 100%
    .closedSlider
        width 0!important
    .main
        margin-left .1rem
        .header
            background #fff
            height .46rem!important
            .title
                line-height .46rem
            .btn-group
                margin-top .08rem
                .btn-item
                    font-size .14rem
                    padding .07rem .15rem
        .main-bottom
            background #fff
            margin-top .1rem
            padding .1rem
            padding-right 0
            height 100%
            .row
                width 100%
                .grid-content
                    height .46rem
                    border 1px solid #d7d7d7
                    .progress
                        color #5588f1
                    .time
                        color #EB656F
                        input
                            border none
                            text-align center
                            color #EB656F
                            font-size .18rem
                            padding-right 0
                        .el-input__suffix
                            display none
                    .state
                        color #21BDCC
                    .options
                        color #FA8436
                    .tit
                        line-height .45rem
                        padding-left .12rem
                        width 1.2rem
                        font-size: .16rem;
                        .iconfont
                            font-size .16rem
                            margin-right .1rem
                        .font18
                            font-size .18rem
                    .iconriqi
                        color #666666
                        line-height .45rem
                        width .3rem
                        text-align center
                        cursor pointer
                    .status
                        width calc(100% - 1.5rem)!important
                        font-size .18rem
                        line-height .45rem
                        text-align: right;
                        padding-right .05rem
                    .el-icon-date
                        color #5588f1
            .report-forms
                height calc(100% - 0.6rem)
                width 100%
                margin-top 0.1rem
                padding-right .1rem
                overflow-y auto
                overflow-x hidden
                .container
                    position relative
                    .slider
                        width 1.77rem!important
                        height auto
                        overflow visible
                        position absolute
                    .edit-table
                        padding 0
                        overflow: visible;
                        margin-left 1.77rem
                        .wrapper
                            // overflow-y auto
                            // overflow-x hidden
                            height auto
                            .custom-table
                                border 1px solid #EBEEF5
                                border-spacing 0px
                                border-collapse collapse
                                width 100%
                                .red
                                    color red
                                    input
                                        color red
                                .border6
                                    border-bottom .06rem solid #eef0f7
                                .classify
                                    width:.4rem;
                                    padding: 0 .1rem;
                                .el-input__inner
                                    width calc(100% - .2rem)
                                    height .32rem
                                    line-height .32rem
                                    margin .1rem
                                    padding 0 .1rem
                                    text-align center
                                    font-size .14rem
                                    color #303030
                                td,th
                                    border 1px solid #EBEEF5
                                    border-collapse collapse
                                    height .38rem
                                    line-height .38rem
                                    text-align center
                                    font-size .15rem
                                    .custom-table
                                        border none
                                .custom-th
                                    // background #F5F7FA
                                    color #0036bb
                                .custom-th-bg
                                    background #F5F7FA
                                .custom-th-odd
                                    background #fefaf4
                                    color #fd8f01
                                .custom-th-even
                                    background #EEF3FE
                                    color #5588F1
                                .border-top-none
                                    border-top: 0;
                                    border-left: 0;
</style>